import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PATHS, PORTAL_PATHS } from "./Path";
import { Loading } from "../Components/Loading/Loading";

// Carregamento Lazy dos componentes
const Home = React.lazy(() => import("../Pages/Home/Home"));
const VizzionDna = React.lazy(() => import("../Pages/VizzionDna/VizzionDna"));
const VizzionInsights = React.lazy(() => import("../Pages/VizzionInsights/VizzionInsights"));
const DashboardContextProvider = React.lazy(() => import("../Pages/Dashboard/Dashboard.context"));
const NewReportFullContextProvider = React.lazy(() => import("../Pages/SolicitacaoRelatorio/SolicitacaoRelatorio.context"));
const MeusRelatoriosContextProvider = React.lazy(() => import("../Pages/MeusRelatorios/MeusRelatorios.context"));
const MeuPerfilContextProvider = React.lazy(() => import("../Pages/MeuPerfil/MeuPerfil.context"));
const ReportcontextProvider = React.lazy(() => import("../Pages/Relatorio/Report.context"));
const LoginContextProvider = React.lazy(() => import("../Pages/Login/Login.context"));
const NotFound = React.lazy(() => import("../Pages/NotFound/NotFound"));

export default function RouterController() {
  const [role, setRole] = useState(null);

  // Função para verificar a role do usuário
  useEffect(() => {
    async function verifyRole() {
      const roleId = await localStorage.getItem("@Coin");
      console.log(role)
      setRole(roleId ? roleId.toString() : null);
    }

    verifyRole();
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.DNA_DO_IMOVEL} element={<VizzionDna />} />
          <Route path={PATHS.INSIGHTS} element={<VizzionInsights />} />
          <Route path={PORTAL_PATHS.LOGIN} element={<LoginContextProvider />} />
          <Route path={PORTAL_PATHS.DASHBOARD_HOME} element={<DashboardContextProvider />} />

          {/* ___ Rotas baseadas na role ___ */}
          {role === "118319312392647338625482390237452" && (
            <>
              <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
            </>
          )}
          
          {role === "9182913143013724274423" && (
            <>
              <Route path={PORTAL_PATHS.NEW_REPORT} element={<NewReportFullContextProvider />} />
              <Route path={PORTAL_PATHS.MY_REPORTS} element={<MeusRelatoriosContextProvider />} />
              <Route path={PORTAL_PATHS.CONFIGS} element={<MeuPerfilContextProvider />} />
              <Route path={`${PORTAL_PATHS.ACESS_REPORT}/:id`} element={<ReportcontextProvider />} />
            </>
          )}

          {/* Redireciona para NotFound caso nenhuma rota corresponda */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
