export const PATHS = {
    HOME: "/",
    SOLUCAO: "/sobre-a-solucao-vizzion",
    CONTATO: "https://api.whatsapp.com/send?phone=554797633714",
    SOBRE: "/sobre",
    PARCEIROS: "/parceiros",
    TRABALHE_CONOSCO: "/trabalhe-conosco",
    SMART_TIME_FOR_CITTIES: "/smart-vizzion-for-smart-citties",
    BANCO_DE_TALENTOS: "/banco-de-talentos",
    NOT_FOUND: "*",
    WHATSAPP: "https://api.whatsapp.com/send?phone=554797633714",
    DNA_DO_IMOVEL: "/vizzion/dna-do-imovel",
    INSIGHTS:"/vizzion/saas/v-insights",
    NOT_FOUND:"*",
}
export const PORTAL_PATHS = {
    DASHBOARD_HOME: '/vizzion/telescope/vizzion-dashboadr',
    LOGIN: '/vizzion/telescope/login',
    MY_REPORTS: '/vizzion/telescope/My-reports',
    CONFIGS: '/vizzion/telescope/my-account-config',
    ALERTS: '/vizzion/telescope/new-alerts',
    NEW_REPORT: '/vizzion/telescope/novoRelatorio',
    ACESS_REPORT: '/vizzion/telescope/acess-report',
    NOT_FOUND:"*",
}